<template>
  <!-- Outer Row -->
  <div class="row justify-content-center">



    <div class="col-xl-10 col-lg-12 col-md-9">
      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-4">С возвращением {{ user.name }}!</h1>
                </div>
                <form @submit="Login" class="user">
                  <div v-if="this.$store.getters.GET_LOCKET_IN" class="form-group text-center">
                    <a v-on:click="this.$store.dispatch('LOGOUT')" class="text-center text-danger" href="#">
                      Я не {{ user.name }}
                    </a>
                  </div>
                  <div v-else class="form-group">
                    <input
                        v-focus
                        v-model="user.email"
                        :class="{ 'is-invalid': error.email}"
                        @input="error = {}"
                        type="email"
                        class="form-control form-control-user"
                        aria-describedby="emailHelp"
                        placeholder="Введите Email адресс..."
                        autocomplete="on"
                        required
                    >
                    <div v-if="error.email" class="invalid-feedback">
                      <span v-for="error in error.email">{{ error }}</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                        v-model="user.password"
                        :class="{ 'is-invalid': error.password}"
                        @input="error = {}"
                        type="password"
                        class="form-control form-control-user"
                        placeholder="Пароль"
                        autocomplete="on"
                        required
                    >
                    <div v-if="error.password" class="invalid-feedback">
                      <span v-for="error in error.password">{{ error }}</span>
                    </div>
                  </div>
                  <div v-if="comment" class="form-group">
                    <div class="custom-control custom-checkbox small">
                      <input type="checkbox" class="custom-control-input" id="customCheck">
                      <label class="custom-control-label" for="customCheck">Запомнить меня</label>
                    </div>
                  </div>
                  <div class="form-group">

                    <div v-if="error.message" class="alert alert-danger" role="alert">
                      {{ error.message }}
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div v-else class="alert alert-primary" role="alert">
                      Введите Email и пароль
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                  </div>
                  <button class="btn btn-primary btn-user btn-block">
                    Войти
                  </button>
                  <!--
                  <hr>
                  <a href="index.html" class="btn btn-google btn-user btn-block">
                    <i class="fab fa-google fa-fw"></i> Login with Google
                  </a>
                  <a href="index.html" class="btn btn-facebook btn-user btn-block">
                    <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook
                  </a>
                  -->
                </form>
                <hr>
                <div class="text-center">
                  <!--<router-link class="small" to="/forgot">Забыли пароль?</router-link>
                  <a class="small" href="forgot-password.html">Забыли пароль?</a>-->
                </div>
                <div class="text-center">
                  <router-link class="small" to="/create">Создать учетную запись!</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import store from "../../store";

export default {
  name: "LoginPage",
  data() {
    return {
      comment: false,
      user: {
        email:'49198@bk.ru',
        password:'',
      },
      error:{}
    }
  },
  mounted() {
    //this.user = this.$store.getters.GET_USER;
    if (this.lockedIn) {
      this.user.name = localStorage.getItem('name');
    }
    this.$store.commit('SET_LOADER_VISIBLE', false);
  },
  computed: {
    loggedIn() {
      return this.$store.getters.GET_LOGGED_IN;
    },
    lockedIn() {
      return this.$store.getters.GET_LOCKET_IN;
    }
  },
  methods: {
    Login: async function (e) {
      e.preventDefault()
      store.commit('SET_LOADER_VISIBLE', true);
      this.error = '';
      try {
        await this.$store.dispatch('LOGIN', this.user);
        await this.$store.dispatch('USER');
        await router.push('home');
      }
      catch (error) {
        console.log(error)
        this.error = error;
      }
      store.commit('SET_LOADER_VISIBLE', false);
    },
  }
}
</script>
<style scoped></style>
